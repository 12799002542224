import React from "react"
import { ThemeProvider } from "emotion-theming"
import theme from "./src/utils/theme"
import 'normalize.css'

import styled from "@emotion/styled"
import { color, fontSize } from "styled-system"
// custom typefaces

export const onInitialClientRender = () => {
}

const Container = styled("div")`
  ${color}
  ${fontSize}
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Container bg="dark" color="light">
      {element}
    </Container>
  </ThemeProvider>
)
