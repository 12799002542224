export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [14, 16, 18, 24, 32],
  colors: {
    blue: "#07c",
    tomato: "tomato",
    purple: "purple",
    dark: "black",
    light: "white",
  },
}
